import React from 'react';
import styled from 'styled-components';
import image from '../assets/images/background2.jpg'
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage'
import movie from '../assets/images/movie.mp4';

const HomeWrapper = styled.div`
    display:flex;
    min-height: 60vh;
`;

const Video = styled.video`
  position: fixed;
  /* right: 0;
  bottom: 0; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-image: ${({ image }) => `url(${image})`};

    ${({ theme }) => theme.media.phone}{
        display: none;
    }
`

const HomePage = () => (
    <HomeWrapper>
        <Video autoPlay muted loop image={image}>
            {/* <source src={movie} type="video/mp4"></source> */}
        </Video>
        <BackgroundImage image={image}>
            <Menu />
            <Footer />
        </BackgroundImage>
    </HomeWrapper>
)

export default HomePage;